import { localStorageHelper, EventEmitter } from 'o365-modules';
import { Url } from 'o365-utils';

export default class TabControl {
    private readonly eventHandler = new EventEmitter();
    private readonly events = {
        TabChanged: 'TabChanged',
    } as const;

    static controlCount = 0;

    tabs: Tab[];
    tabsId: string;
    lastIndex?: number;
    urlFilter: boolean;
    compactView: boolean;
    compactViewMode?: string;
    private _localKey?: string;
    private get localKey() {
        return this._localKey == null ? null : `tabsPersistent_${this._localKey}`;
    }

    private _storedTabId?: string | null;

    constructor(options: {
        persistentKey?: string,
        enableUrlFilter?: boolean,
        id?: string
    } = {}) {
        this.tabs = [];
        this.tabsId = options.id ?? `${TabControl.controlCount++}`;
        this._localKey = options.persistentKey;
        this.urlFilter = options.enableUrlFilter ?? false;
        this._storedTabId = this._getUrlStoredTab() ?? this._getStoredTab();
        this.compactView = false;
    }

    addTab(tab: Tab, updateActive: (newActive: boolean) => void) {
        if (!tab.id) {
            tab.id = `o365-tab-${this.tabsId}_${this.tabs.length}`;
        }

        if (this._storedTabId != null) {
            const isActive = this._storedTabId === tab.id;
            tab.rendered = isActive;
            tab.active = isActive;
            updateActive(isActive);
        }

        tab.index = this.tabs.length;
        this.tabs.push(tab);
    }

    removeTab(tab: Tab) {
        if (!tab || tab.index == null) { return; }
        
        this.tabs.splice(tab.index, 1);
        this.tabs.forEach((item, index) => item.index = index);
    }

    setActiveTab(index: number) {
        this.lastIndex = this.tabs.findIndex((x: Tab) => x.active);
        this.tabs.forEach((tab: Tab, tabIndex: number) => {
            if (tab.active) { this.lastIndex = tabIndex; }

            const isActive = tabIndex === index;
            tab.active = isActive;
            const tabID = tab.id.split('_').length === 1 ? tab.id : tab.id.split('_')[1]
            if (isActive) {
                this._storedTabId = tab.id;
                this.emit(this.events.TabChanged, { tabId: tab.id });
                this._storeActiveTab(this.tabs[tabIndex].id);
                if (this.urlFilter) {
                    if (isNaN(this.tabsId)) {
                        Url.setQueryParameter(this.tabsId, `${tab.id ?? tabIndex}`);
                    } else {
                        Url.setQueryParameter(`tabs_${this.tabsId}`, `${tabID}`);
                    }
                }
                
            }
        });
    }

    sortTabs(pCompareFn: (a: Tab, b: Tab) => number) {
        this.tabs.sort(pCompareFn);
        this.tabs.forEach((tab, index) => {
            tab.index = index;
        });
    }

    cacheHiddenTab() {
        if (this.lastIndex == null || this.tabs[this.lastIndex] == null) { return; }
        this.tabs[this.lastIndex].rendered = false;
    }

    /** Get the active tab from local storage */
    private _getStoredTab(): string|null {
        try {
            if (this._localKey == null) { return null; }
            const lastActiveTabId = localStorageHelper.getItem(this.localKey!) ?? null;
            if (lastActiveTabId === '') { return null; }
            return lastActiveTabId;
        } catch (ex) {
            return null;
        }
    }

    /** Save the active tab to local storage */
    private _storeActiveTab(tabId: string) {
        if (this._localKey == null) { return; }
        localStorageHelper.setItem(this.localKey!, tabId);
    }

    /** Get the active tab from url filter */
    private _getUrlStoredTab(): string|null {
        try {
            if (!this.urlFilter) { return null; }
            const searchUrl = window.location.search;
            const urlSearchParameters = new URLSearchParams(searchUrl);
            let tabID: string|number|null = null;

            if (urlSearchParameters.size === 0 ) { return null; }

            for (const [key, value] of urlSearchParameters.entries()) {
                if (key.includes('tabs_')) {
                    tabID = value;
                    break;
                } else if (key.includes(this.tabsId)) {
                    tabID = value;
                    break;
                }
            }
            
            if (tabID) {
                if (isNaN(tabID)) {
                    return tabID;
                } else {
                    return `o365-tab-${this.tabsId}_${tabID}`;
                }
            }
            
        } catch (ex) {
            return null;
        }
    }

    on(event: string, listener: (...args: any[]) => any) {
        return this.eventHandler.on(event, listener);
    }

    off(event: string, listener: (...args: any[]) => any) {
        return this.eventHandler.off(event, listener);
    }

    once(event: string, listener: (...args: any[]) => any) {
        return this.eventHandler.once(event, listener);
    }

    removeAllListeners() {
        return this.eventHandler.removeAllListeners();
    }

    emit(event: string, ...args: Array<any>) {
        return this.eventHandler.emit(event, ...args, this);
    }
}


export class Tab {
    private _props: TabProps;

    private _id: string
    private _active: boolean;

    rendered: boolean;
    index?: number;
    elRef: Ref<HTMLElement>;
    titleSlot: Function;

    get props() { return this._props; }

    get id() { return this._id; }
    set id(pValue) { this._id = pValue;}

    get active() { return this._active}
    set active(pValue) { this._active = pValue; }

    get tabIndex() { return this._props.tabIndex; }
    get title() { return this._props.title; }
    get alwaysRender() { return this._props.alwaysRender; }
    get paneClass() { return this._props.paneClass; }
    get paneStyle() { return this._props.paneStyle; }
    get itemClass() { return this._props.itemClass; }
    get itemStyle() { return this._props.itemStyle; }
    get linkClass() { return this._props.linkClass; }
    get linkStyle() { return this._props.linkStyle; }

    constructor(options: {
        props: TabProps,
        elRef: Ref<HTMLElement>,
        titleSlot: Function,
    }) {
        this._props = options.props;
        this._id = options.props.id ?? '';
        this._active = options.props.active ?? false;
        this.elRef = options.elRef;
        this.rendered = !!this.active;
        this.titleSlot = options.titleSlot;
    }
}

// Copy of tab props type for inelisense
type TabProps = {
    id?: string,
    tabIndex?: string | number,
    active?: boolean,
    title?: string,
    alwaysRender?: boolean,
    paneClass?: any,
    paneStyle?: any,
    itemClass?: any,
    itemStyle?: any,
    linkClass?: any,
    linkStyle?: any,
    disabled?: boolean
};

type Ref<T> = {
    value: T
};
